
import React, { useState, useCallback } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export const useEmailSubmit = () => {
    const [email, setEmail] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSubmit = useCallback(
        async (ev: any) => {
            ev.preventDefault();
            if (!email) {
                return;
            }
            const res = await addToMailchimp(email);
            console.log(res);
            if (res.result === 'success') {
                alert(`Thank you for signing up! We will be in touch when we have updates`)
            } else {
                alert(res.msg + ` 🙂`)
            }
        },
        [email]
    );

    return {
        handleChange,
        handleSubmit,
    };
};